import {
  ApolloClient,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import localForage from 'localforage';

import { CONFIG } from 'app/configurator';

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Model':
        return `Model:${responseObject.modelId}`;
      case 'TrainingResult':
        return `TrainingResult:${responseObject.trainingResultId}`;
      case 'BenchmarkResult':
        return `BenchmarkResult:${responseObject.benchmarkResultId}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

const localForageInstance = localForage.createInstance({
  name: 'apollo-cache',
});

const cachePersistor = new CachePersistor<NormalizedCacheObject>({
  cache,
  debug: true,
  // 20MB
  maxSize: 1048576 * 20,
  storage: new LocalForageWrapper(localForageInstance),
});

const PERSIST_LAST_PURGE_KEY = 'sparsezoo'; // if schema changed we can change key
const PERSIST_TTL = 1000 * 60 * 30; // in milliseconds, 30 mins
const lastPurge = localStorage.getItem(PERSIST_LAST_PURGE_KEY);
if (!lastPurge || Number(lastPurge) < Date.now() - PERSIST_TTL) {
  localStorage.setItem(PERSIST_LAST_PURGE_KEY, String(Date.now()));
  cachePersistor.purge();
} else {
  cachePersistor.restore();
}

const isCacheReadyPromise = cachePersistor.restore();

const apolloClient = new ApolloClient({
  cache,
  uri: `${CONFIG.modelApiServiceBaseUrl}/v2/graphql`,
});

export const getClient = async (): Promise<ApolloClient<NormalizedCacheObject>> => {
  await isCacheReadyPromise;
  return apolloClient;
};
