type ConfigConstructor = {
  enableNewRelic: boolean;
  gitSha: string;
  modelApiServiceBaseUrl: string;
  version: string;
  gaId: string;
};

export class Config {
  _enableNewRelic: boolean;

  _gitSha: string;

  _modelApiServiceBaseUrl: string;

  _version: string;

  _gaId: string;

  constructor({
    enableNewRelic,
    gitSha,
    modelApiServiceBaseUrl,
    version,
    gaId,
  }: ConfigConstructor) {
    this._enableNewRelic = enableNewRelic;
    this._modelApiServiceBaseUrl = modelApiServiceBaseUrl;
    this._gitSha = gitSha;
    this._version = version;
    this._gaId = gaId;
  }

  get enableNewRelic(): boolean {
    return this._enableNewRelic;
  }

  get gitSha(): string {
    return this._gitSha;
  }

  get modelApiServiceBaseUrl(): string {
    return this._modelApiServiceBaseUrl;
  }

  get version(): string {
    return this._version;
  }

  get gaId(): string {
    return this._gaId;
  }
}
