import { Config } from './Config';

type JsonConfig = {
  enableNewRelic: boolean;
  gitSha: string;
  modelApiServiceBaseUrl: string;
  version: string;
  gaId: string;
};

type SparseZooGlobal = {
  config?: JsonConfig;
};

interface SparseZooWindow extends Window {
  __NM_SPARSEZOO__?: SparseZooGlobal;
}

const DEFAULT_CONFIG: Config = new Config({
  enableNewRelic: false,
  gitSha: process.env.REACT_APP_SPARSEZOO_UI_GIT_SHA || 'NONE',
  modelApiServiceBaseUrl:
    process.env.REACT_APP_SPARSEZOO_API_SERVICE_BASE_URL ||
    'https://models-api.staging.neuralmagic.com',
  version: process.env.REACT_APP_SPARSEZOO_UI_VERSION || 'NONE',
  gaId: process.env.REACT_APP_GA_ID || 'NONE',
});

const sparseZooWindow = window as SparseZooWindow;

const ENV_CONFIG = sparseZooWindow?.__NM_SPARSEZOO__?.config
  ? new Config(sparseZooWindow.__NM_SPARSEZOO__.config)
  : undefined;

export const CONFIG: Config = ENV_CONFIG || DEFAULT_CONFIG;
