import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import React, { lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import reportWebVitals from './reportWebVitals';
import { getClient } from 'api/apolloClient';
import './App.css';

const App = lazy(() => import('./app/App'));
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWrapper = () => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null
  );

  useEffect(() => {
    getClient().then((client) => {
      if (client !== null) {
        setApolloClient(client);
      }
    });
  }, []);

  return (
    <div style={{ background: '#F6FBFE' }}>
      {apolloClient ? (
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      ) : null}
    </div>
  );
};

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
